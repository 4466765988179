/**
 * Part of Genesis application
 *
 * @copyright 2025 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import axios from 'axios'

export const LadingBill = {
    async destroy(freight_id, lading_bill_id) {
        await axios.delete(`/api/freights/${freight_id}/lading_bills/${lading_bill_id}`)
    },
    async store(freight_id) {
        const response = await axios.post(`/api/freights/${freight_id}/lading_bills`)

        return response.data
    },
    async show(freight_id, lading_bill_id) {
        const response = await axios.get(`/api/freights/${freight_id}/lading_bills/${lading_bill_id}`)

        return response.data.data
    },
    async update(freight_id, lading_bill_id, data) {
        await axios.patch(`/api/freights/${freight_id}/lading_bills/${lading_bill_id}`, data)
    },
}

export default LadingBill