/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import customerVariant from './customerVariant'
import departurePass from './departurePass'
import driver from './driver'
import ediLocation from './ediLocation'
import freight from './freight'
import freightTrip from './freightTrip'
import freightTripType from './freightTripType'
import freightType from './freightType'
import ladingBill from './ladingBill'
import loadTender from './loadTender'
import locality from './locality'
import locationsDistance from './locationsDistance'
import operatorDocument from './operatorDocument'
import serviceRequest from './serviceRequest'
import trailer from './trailer'
import trailerOwner from './trailerOwner'
import vehicle from './vehicle'
import vehicleInsurance from './vehicleInsurance'
import vehiclePermit from './vehiclePermit'

/** @module genesis/traffic */

/** Genesis Traffic service module. */
export const Traffic = {
    customerVariant,
    departurePass,
    driver,
    ediLocation,
    freight,
    freightTrip,
    freightTripType,
    freightType,
    ladingBill,
    loadTender,
    locality,
    locationsDistance,
    operatorDocument,
    serviceRequest,
    trailer,
    trailerOwner,
    vehicle,
    vehicleInsurance,
    vehiclePermit,
}

export default Traffic